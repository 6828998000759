<template>
  <div>
    <!--                一类-->
    <div class="context-list-category-a">
      <div>
        <span>{{ data.categoryCode.length > 1 ? data.categoryCode : '0' + data.categoryCode }}</span>
        <span>{{ data.categoryName }}</span>
      </div>
      <div>
        <span class="subtitle">价格</span> <span>￥{{ parent.totalFee }}</span>
        <Icon
            @click="foldHandle"
            name="arrow-up"
            class="icon-fold"
            :style="{'transform': fold ? 'rotate(180deg)' : 'rotate(0deg)'}"></Icon>
      </div>
    </div>
    <!--                二类-->
    <div v-for="item in data.child" v-show="!fold">
      <div class="context-list-cell-b">
        <span>{{ item.categoryCode.length > 3 ? item.categoryCode : '0' + item.categoryCode }}</span>
        <span>{{ item.categoryName }}</span>
      </div>
      <!--                  三类-->
      <div class="flex">
        <div class="context-list-cell-c" v-for="_item in item.child">
<!--          <span>{{ _item.categoryCode }} </span>-->
          <span>{{ _item.categoryName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from 'vant'

export default {
  name: "TheBrandCategory",
  data() {
    return {
      fold: false
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {

      }
    },
    parent: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    foldHandle() {
      this.fold = !this.fold;
    }
  },
  components: {Icon}
}
</script>

<style scoped>
.context-list-category-a {
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  margin: 14px 0 10px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  padding:0 15px 0 60px;
}
.context-list-cell-b {
  color: #999;
  font-size: 13px;
  margin-top: 14px;
  padding: 0 14px 0 30px;
}

.context-list-cell-c {
  color: #FF5E00;
  font-size: 12px;
  margin-right: 14px;
  margin-top: 10px;
}

.icon-fold {
  color: #666;
  transition: all ease .3s;
}
</style>