import request from '@/utils/request'

/**
 * 通过交易流水号，查询订单你信息（用于找人代付，订单信息查询）
 * @param data
 * @returns {AxiosPromise}
 */
export function getOrderInfoByTradeCode(params) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/transaction/service/open/getOrderInfoByTradeCode',
        method: 'get',
        params
    })
}

/**
 * 通过订单号集合，查询订单信息（用于找人代付，订单信息查询）
 * @param data
 * @returns {AxiosPromise}
 */
export function queryOrderInfoForPaidAo(data) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/orderv2/webapi/queryOrderInfoForPaidAo',
        method: 'POST',
        data
    })
}


/**
 * 订单支付（用于找人代付，订单支付）
 * /transaction/service/open/orderPay
 */
export function wxPay(data) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/payOrder/wxPay',
        method: 'post',
        data
    })
}

/**
 * 订单支付（用于找人代付，订单支付）
 * /transaction/service/open/orderPay
 */
export function offlinePay(data) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/payOrder/offlinePay',
        method: 'post',
        data
    })
}

/**
 * 微信支付 获取openId
 * /user/service/getOpenId
 */
export function getOpenId(data) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/user/service/getOpenIdForGzh',
        method: 'post',
        data
    })
}

/**
 * 获取payCode
 */
export function getPayCode(data) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/transactionv2/webapi/getPayCode',
        method: 'post',
        data
    })
}


/**
 * 确认支付
 * /transaction/service/open/confirmPay
 */
export function confirmPay(data) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/transaction/service/open/confirmPay',
        method: 'post',
        data
    })
}