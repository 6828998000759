<template>
  <div>
    <NavBar fixed title="支付订单"></NavBar>
    <div class="context-wrap">
      <template v-for="(item,index) in paidOrderInfoTotals">
        <!--      商标信息-->
        <div class="context-box" v-if="item.orderType !== '1'">
          <div class="context-list">
            <div class="title">{{ item['orderTypeMsg'] }}</div>
            <div class="context-title">订单信息</div>
            <div class="context-list-item" v-for="(_item,index) in item['sameTypeOrder']" :key="index">
              <div class="context-list-cell">
                <span>{{ item['orderTypeMsg'] }}</span>
                <span>数量X1</span>
              </div>
              <div>
                <span class="subtitle">价格</span> <span class="money">￥{{ _item['totalFee'] }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--      商标展示-->
        <div class="context-box" v-show="item.trademarkName || item.trademarkImgUrl">
          <div class="context-list">
            <div class="context-list-row" style="justify-content: left;margin-top:14px;" v-if="!!item.trademarkName">
              <div>商标名称</div>
              <div style="margin-left:10px;">
                {{ item.trademarkName }}
              </div>
            </div>
            <div class="context-list-row" style="justify-content: left;" v-if="!!item.trademarkImgUrl">
              <div>商标图片</div>
              <div style="margin-left:10px;">
                <VanImage class="poster-box" :src="item.trademarkImgUrl"></VanImage>
              </div>
            </div>
          </div>
        </div>
        <!--      商标注册类别-->
        <div class="context-box" v-if="item['orderTypeMsg'] === 'AI商标注册'">
          <div class="context-list">
            <div class="context-title">商标注册类别</div>
            <div v-if="item['sameTypeOrder'] && item['sameTypeOrder'].length > 0" v-for="(_item,_index) in item['sameTypeOrder']" :key="_index">
              <div v-if="_item['trademarkCateList'] && _item['trademarkCateList'].length > 0" v-for="(__item,__index) in _item['trademarkCateList']" :key="__index">
                <TheBrandCategory :data="__item" :parent="_item"></TheBrandCategory>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--      发票信息-->
      <div class="context-box" v-if="invoiceInfo && invoiceInfo.invoiceCode">
        <div class="context-list">
          <div class="context-title">发票信息</div>
          <div class="form-content">
            <div class="form-item">
              <div class="form-label">公司名称</div>
              <div class="form-value">{{ invoiceInfo.invoiceName }}</div>
            </div>
            <div class="form-item">
              <div class="form-label">税号</div>
              <div class="form-value">{{ invoiceInfo.taxNum }}</div>
            </div>
            <div class="form-item">
              <div class="form-label">详细地址</div>
              <div class="form-value">{{ invoiceInfo.companyAddr }}</div>
            </div>
            <div class="form-item">
              <div class="form-label">公司电话</div>
              <div class="form-value">{{ invoiceInfo.companyTel }}</div>
            </div>
          </div>
        </div>
      </div>
      <!--      支付方式-->
      <div class="context-box" v-if="isPay">
        <div class="context-list">
          <div class="context-title">支付方式</div>
          <div>
            <RadioGroup v-model="payType" @change="onChange">
              <div class="context-list-row">
                <div>
                  <img width="20px" :src="wechatPay" style="margin-right:10px;"/>
                  <div>微信支付</div>
                </div>
                <span>
                  <Radio name="1" :checked-color="checkboxColor"></Radio>
                </span>
              </div>
              <div class="context-list-row">
                <div>
                  <img width="20px" :src="bankCard" style="margin-right:10px;"/>
                  <div>银行卡支付</div>
                </div>
                <span>
                  <Radio name="3" :checked-color="checkboxColor"></Radio>
                </span>
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
      <template v-if="payType === '3' && isPay">
        <div class="context-box">
          <div class="form-content">
            <div class="form-item">
              <div class="form-label">汇款公司</div>
              <Field v-model="remittanceCompany" placeholder="请输入汇款公司名称"/>
            </div>
          </div>
        </div>
        <div class="context-box">
          <div class="form-content">
            <div class="form-item-title">
              打款账户信息
            </div>
            <div class="form-item">
              <div class="form-label">公司名称：</div>
              <div class="form-value">
                杭州果老网知识产权代理有限公司
              </div>
            </div>
            <div class="form-item">
              <div class="form-label">开户行：</div>
              <div class="form-value">
                平安银行杭州分行营业部
              </div>
            </div>
            <div class="form-item">
              <div class="form-label">账户：</div>
              <div class="form-value">
                {{ payInfo.account }}
              </div>
            </div>
            <Button class="button-copy" type="default" size="mini" round @click="copyHandle">复制</Button>
          </div>
        </div>
      </template>
    </div>
    <!--    底部-->
    <div class="bottom-menu">
      <div>
        <div>
          <span>合计：</span>
          <span class="money">￥{{ totalFee }}</span>
        </div>
        <div>
          <!--          <span class="subtitle">共2件</span>-->
        </div>
      </div>
      <div v-if="isPay">
        <Button class="button-pay" @click="payHandle" round color="#FF5E00">去支付</Button>
      </div>
      <div v-if="!isPay">
        {{ payStatusText }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Skeleton,
  NavBar,
  Image as VanImage,
  Checkbox,
  Field,
  Toast,
  Icon,
  Radio,
  RadioGroup,
  Dialog
} from 'vant'
import bankCard from '@/assets/bankCard.png'
import wechatPay from '@/assets/wechatPay.png'

import {
  confirmPay,
  getOpenId,
  getOrderInfoByTradeCode,
  getPayCode, offlinePay,
  queryOrderInfoForPaidAo, wxPay
} from "@/api/pay";
import TheBrandCategory from "@/views/order/components/TheBrandCategory";
import {randomString} from "@/utils/utils";
import request from "@/utils/request";

export default {
  name: "orderDetail",
  data() {
    return {
      bankCard,
      wechatPay,
      checkboxColor: '#FF5E00',
      orderDetail: {
        title: '商标续展'
      },
      brandInfo: [
        {
          title: '商标续展',
          num: '1'
        },
        {
          title: '商标续展',
          num: '3'
        }
      ],
      brandCategory: [],
      invoiceInfo: {
        comName: '',
        taxNumber: '',
        comAddress: '',
        comPhone: ''
      },
      remittanceCompany: '',
      payInfo: {
        account: '1508380034007'
      },
      totalFee: 0,
      payType: '1',
      payStatus: '1',
      paidOrderInfoTotals: [],
      payCode: "",
      openId:""
    }
  },
  mounted() {
    this.getData();
    // if(this.isWeiXin()) this.getCode();
  },
  computed: {
    state() {
      if(this.$route.query.state) return this.$route.query.state.split(',')
      return ''
    },
    isPay(){
        return Number(this.payStatus) === 0
    },
    payStatusText() {
      //支付状态
      let props = {
        "0": "待支付",
        "1": "支付中",
        "2": "支付成功",
        "3": "支付失败"
      }
      return props[this.payStatus] || ''
    }
  },
  methods: {
    initWeixinJSBridge() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      let flag = ua.match(/MicroMessenger/i) == 'micromessenger';
      return !!flag
    },
    onBridgeReady(params) {
      params['package'] = params.prepayId
      let _this = this;
      WeixinJSBridge.invoke('getBrandWCPayRequest',
          {...params},
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              Toast("微信支付成功");
              setTimeout(() => {
                location.reload();
              }, 1000)
            } else if (res["err_msg"] === 'get_brand_wcpay_request:cancel') {
              Toast('取消支付')
            }
          }
      );
    },
    copyHandle() {
      let aux = document.createElement("input");
      aux.setAttribute("value", this.payInfo.account);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      Toast('复制成功');
    },
    async payHandle() {
      if (!this.payType) {
        Toast.fail('请选择支付方式');
        return false
      }
      //获取payCode
      let payCode = await this.getPayCode()
      switch (this.payType) {
        case "1"://微信支付
          await this.wxPayHandle(payCode);
          break;
        case "3"://银行卡转账
          await this.bankCardPayHandle(payCode)
          break;
      }
    },
    async getPayCode() {//获取payCode
      let orderCodeList = this.state || [];
      let res =await getPayCode({
        orderCodeList,
        goodsType:'1'
      })
      let {code, data} = res;
      if (code === 200 && data) {
        return data
      }
      return ''
    },
    async wxPayHandle(payCode) {
      //去支付 操作
      let openId = await this.getOpenId(this.$route.query.code)
      if(openId && openId !== 'null') this.openId = openId
      if (!openId) {
        Toast('openId获取失败，请稍后重试')
        return
      }
      let requestPayType = this.isWeiXin() ? 'JSAPI' : 'MWEB'
      let params = {
        // "deviceId": "",
        // "ipAddress": "",
        // "payPrice": this.totalFee,
        // "payType": this.payType,
        // "platformType": 5,
        //
        // "tradeCode": this.state,
        // requestPayType,
        "tradeType":requestPayType,
        openId:this.openId || openId,
        payCode,
        "returnUrl": window.location.href
      }
      let res = await wxPay(params)
      if (res.code === 200) {
        switch (requestPayType) {
          case "JSAPI":
            this.payCode = res.data.payCode
            this.onBridgeReady(res.data)
            break;
          case "MWEB":
            let {mwebUrl} = res.data;
            if (mwebUrl) window.location.href = mwebUrl;
            break;
        }
      }
    },
    async bankCardPayHandle(payCode) {//银行卡支付操作
      if (!this.remittanceCompany) {
        Toast('请输入汇款公司名称');
        return false
      }
      let params = {
        // "deviceId": "",
        // "ipAddress": "",
        // "payPrice": this.totalFee,
        // "payType": this.payType,
        // "platformType": 5,
        //
        // "returnUrl": window.location.href,
        // "tradeCode": this.state,

        "remittanceCompany": this.remittanceCompany,
        payCode
      }
      let res = await offlinePay(params)
      if (res.code === 200) {
        await this.getData()
        Toast('待线下付款成功');
      }
    },
    async getData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let orderCodeList = this.state || null;
      let res = await queryOrderInfoForPaidAo({orderCodeList});
      if (res.code === 200) {
        let {totalFee, paidOrderInfoTotals, invoiceInfo, payStatus} = res.data;
        this.payStatus = payStatus;
        this.totalFee = totalFee;
        this.paidOrderInfoTotals = paidOrderInfoTotals || [];
        //发票信息
        this.invoiceInfo = invoiceInfo ? invoiceInfo : null;
      }
    },
    onChange(e) {
      console.log(e);
    },
    async getOpenId(code = '') {
      let res = await getOpenId({code})
      if (res.code === 200) {
        return res.data.openId
      }
    },
    async getCode() {//获取code
    // wxdf6b47ebbe5773a7
    let appId = 'wxdf6b47ebbe5773a7'
      // let appId = 'wx806766edadf0ee16'
      let redirect_uri = process.env.VUE_APP_BASE_URL || 'https://h5.guolaoai.com';
      let url = `
                https://open.weixin.qq.com/connect/oauth2/authorize?
                appid=${appId}&
                redirect_uri=${encodeURI(redirect_uri)}&
                scope=snsapi_base&
                response_type=code
                #wechat_redirect`
      window.location.href = url
    }
  },
  components: {
    TheBrandCategory,
    Button, Skeleton, NavBar, VanImage, Checkbox, Field, Toast, Icon, Radio, RadioGroup
  }
}
</script>

<style scoped>

.context-wrap {
  overflow: scroll;
  margin-top: 46px;
  padding-bottom: 60px;
  min-height: calc(100vh - 46px - 60px);
}

.context-box {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  margin: 6px 10px;
  align-items: center;
}

.context-title {
  font-size: 14px;
  margin: 14px;
  font-weight: bold;
}

.context-list {
  width: 100%;
  padding-bottom: 14px;
}

.context-list-item {
  border-bottom: .5px solid #e6e6e6;
  padding: 14px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
}

.context-list-item > div {
  margin-right: 32px;
}

.context-list-item > div:last-child {
  margin-right: 0;
}

.context-list-item:first-child {
  padding-top: 0;
}

.context-list-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}


.context-list-cell > span {
  margin-right: 14px;
}

.context-list-cell {

}


.poster-box {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.title {
  font-size: 16px;
  font-weight: bold;
  padding: 16px;
}

.subtitle {
  color: #666;
  font-size: 13px;
}

.money {
  color: #FF5E00
}

.form-content {
  padding: 0 14px;
  position: relative;
  width:100%;
}

.form-item-title {
  margin: 9px 0;
  align-items: center;
  font-size: 13px;
}

.form-item {
  margin: 9px 0;
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 13px;
}

.form-item:last-child {
  margin-bottom: 0;
}

.form-item:first-child {
  margin-top: 0;
}

.form-label {
  width: 80px;
  color: #666;
  font-size: 13px;
}

.form-value {
  font-size: 13px;
  flex:1;
}

.context-list-row {
  padding: 9px 14px;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
}

.context-list-row > div {
  display: flex;
  align-items: center;
}

.context-list-row:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.button-copy {
  position: absolute;
  right: 14px;
  bottom: 5px;
}

.button-pay {
  padding: 0 30px;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  height: 60px;
  align-items: center;
}

.bottom-menu > div {
  padding: 0 14px;
}
</style>
